// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  home: $(".mainSlider"),
  logo: $(".logoCarousel")
};

sliders.home.slick({
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 3500,
  fade: true,
  pauseOnHover: false
});

sliders.logo.slick({
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 500,
  arrows: false,
  dots: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});
