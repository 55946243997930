import {isMobile, isTablet} from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  console.log(isMobile());
  const menu = document.getElementById("menu-header");
  if (!isTablet()) {
    menu.style.display = "flex";
  } else {
    menu.style.display = "none";
  }
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
